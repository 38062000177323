.faq-popover {
  .MuiPopover-paper {
    bottom: 20px;
    right: 20px;
  }
}

.shop-page-dialog {
  .MuiPaper-rounded {
    border-radius: 0px !important;
  }

  .MuiDialog-paperWidthSm {
    max-width: 850px !important;
  }

  .MuiBackdrop-root {
    background: none !important;
  }
}

.dialog-wrapper {
  position: relative;
  text-align: center;
  padding: 20px;
  text-align: center;
  background: $remedi-purple;
  color: white;

  .close {
    position: absolute;
    bottom: 65px !important;
    right: 10px;
    button {
      padding: 0;
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    .sunny-logo,
    .jane-logo {
      margin: 0 auto 25px;
      display: block;
      border-radius: 10px;
    }
    .sunny-logo {
      width: 150px;
    }
    .jane-logo {
      width: 86px;
    }
    .shop-sunny {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      padding-bottom: 35px;
      margin-bottom: 32px;
    }

    a {
      text-decoration: none;
      button {
        font-size: 12px;
        background: none;
        color: $remedi-purple;
        background: white;
        transition: 200ms ease;
        white-space: normal !important;
      }
    }
  }
  .logo-container {
    margin-bottom: 40px;
    padding: 15px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    svg,
    img {
      max-width: 120px;
      margin: auto;
      path {
        fill: white;
      }
    }
  }
  .content {
    margin: 90px auto 10px;
    max-width: 450px;

    p {
      font-size: 16px;
      color: white;
    }
  }
  .disclaimer-text {
    p {
      font-size: 12px;
      line-height: 16px;
      max-width: 500px;
      margin: 35px auto 0 auto;
      color: white;
      a {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .dialog-wrapper {
    .button-container {
      flex-direction: row;
      margin-top: 30px;
      .sunny-logo,
      .jane-logo {
        display: block;
      }
      .sunny-logo {
        width: 198px;
        margin-bottom: 35px;
      }
      .jane-logo {
        width: 104px;
      }
      .shop-sunny {
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
        padding: 15px 35px 0 35px;
      }
      .shop-jane {
        padding: 15px 35px 0 35px;
      }

      a {
        button {
          font-size: 16px;
        }
      }
    }
    .logo-container {
      margin-bottom: 20px;
      svg {
        max-width: 160px;
      }
      .close {
        bottom: 90px;
      }
    }
    .content {
      margin-bottom: 50px;
      margin-top: 110px;
      p {
        font-size: 20px;
      }
    }
    .disclaimer-text {
      margin-top: 50px;
    }
  }
}
