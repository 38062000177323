.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40px {
  margin-top: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 10px !important;
}
.mr-10 {
  margin-right: 20px !important;
}
.mr-15 {
  margin-right: 30px !important;
}
.mr-20 {
  margin-right: 40px !important;
}

.ml-5 {
  margin-left: 10px !important;
}
.ml-10 {
  margin-left: 20px !important;
}
.ml-15 {
  margin-left: 30px !important;
}
.ml-20 {
  margin-left: 40px !important;
}
